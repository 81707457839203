<template>
  <div id="login">
    <el-form>
      <img class="logo" src="../../assets/images/mine/logo.png" alt="">
      <h1 class="title">门店管理系统</h1>
      <p class="explain">一款深度定制的智慧门店系统，带你走向智控</p>
      <!-- <li class="login-way">
        <span :class="isActive && 'way-active'" @click="isActive = true"
          >密码登录</span
        >
        <i class="mid-line"></i>
        <span :class="!isActive && 'way-active'" @click="isActive = false"
          >验证码登录</span
        >
      </li> -->
      <!-- 账户 -->
      <el-input v-model.number="params.userMobile" placeholder="请填写手机号" prefix-icon="el-icon-phone" clearable
        @input="onSpIdList" maxlength="11"></el-input>
      <!-- 密码 -->
      <el-input v-if="isActive" v-model="params.password" placeholder="请填写密码" show-password prefix-icon="el-icon-lock"
        @keydown.enter="login()"></el-input>
      <!-- 验证码 -->
      <el-input v-if="!isActive" v-model="securitycode" placeholder="请填写验证码" prefix-icon="el-icon-message">
        <template #append>
          <span class="securitycode" @click="setSecuritycode()">发送验证码</span>
        </template>
      </el-input>
      <!-- 服务商 -->
      <el-select v-model="params.spId" placeholder="请选择服务商" style="width: 100%" @change="change()">
        <template #prefix>
          <i class="el-input__icon el-icon-office-building"></i>
        </template>
        <el-option v-for="item in spIdList" :key="item.spId" :label="item.spName" :value="item.spId">
        </el-option>
      </el-select>
      <!-- 验证码 -->
      <el-input placeholder="请填写验证码" v-model="params.imageCode" maxlength="4">
        <template slot="append">
          <img style="width: 100px; height: 30px;cursor: pointer;" :src="imageBase64Data" @click="getImageCode">
        </template>
      </el-input>
      <el-button class="login-btn" type="primary" @click="login()" @keyup.enter="keyDown(e)">登 录</el-button>
    </el-form>
    <div class="reg" @click="linkReg">忘记密码？</div>
  </div>
</template>

<script>
import { getToken, getSpIdList, getImageCode } from "@/api/setting/user";
export default {
  data() {
    return {
      spIdList: [],
      imageBase64Data: "",//验证码图片
      params: {
        userMobile: "", //账户
        password: "", //密码
        spId: null,//服务商id
        codeId: null,//验证码id
        imageCode: "",//验证码
      },
      isActive: true, //登录方式
    };
  },
  created() {
    this.getImageCode();
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 【请求】获取图片验证码
    getImageCode() {
      getImageCode().then(res => {
        if (res.isSuccess == "yes") {
          this.params.imageCode = "";
          this.params.codeId = res.data.codeId;
          this.imageBase64Data = res.data.imageBase64Data;
        }
      })
    },

    // 【请求】获取服务商列表
    onSpIdList: _.debounce(function () {
      let data = {
        sysId: 2,//1平台端。2：门店端
        userMobile: this.params.userMobile,
      };
      getSpIdList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.spIdList = res.data;
          this.$nextTick(() => {
            if (res.data) {
              this.params.spId = res.data[0].spId
            }
          });
        }
      })
    }, 1000),

    change() {
      this.$forceUpdate()
    },

    // 登录
    login: _.throttle(function () {
      let data = this.params;
      getToken(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$router.push({
            path: "/home",
          });
          window.localStorage.setItem('playOne-store-token', res.data);//存入token
        } else {
          this.getImageCode();
        }
      });
    }, 1000),

    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.key == 'Enter') {
        this.login(); // 定义的登录方法
        e.preventDefault(); // 去掉默认的换行
      }
    },



    // 短信验证
    setSecuritycode: _.throttle(function () {
      this.$message({
        message: "发送成功",
        type: "success",
      });
    }, 1000),
    // 跳转注册
    linkReg: _.throttle(function () {
      this.$router.push({
        path: "/mine/register",
      });
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#login {
  width: 3rem;
  height: 5rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
}

.el-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: .68rem;
  height: .68rem;
}

.title {
  font-size: .28rem;
  font-family: 微软雅黑;
  letter-spacing: 3px;
  font-weight: bold;
}

.login-btn {
  width: 100%;
  margin-top: .3rem;
}

.explain {
  margin-bottom: .3rem;
  font-size: .12rem;
}

.login-way {
  width: 100%;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-around;
  font-size: 0.2rem;
  font-weight: bold;
}

.login-way>span {
  cursor: pointer;
  color: #999;
}

.way-active {
  color: #000 !important;
}

.mid-line {
  width: 1px;
  background: rgb(219, 219, 219);
}

.securitycode {
  color: #409eff;
  cursor: pointer;
}

.reg {
  margin: 0.1rem 0;
  width: 100%;
  text-align: center;
  color: #409eff;
  cursor: pointer;
  font-size: 0.12rem;
}
</style>
<style lang="scss">
#login {
  .el-input-group__append {
    padding: 0 .1rem;
    height: 100%;
  }
}
</style>
